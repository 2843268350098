.toggle-switch {
  position: relative;
  margin-right: 10px;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid var(--primary-color-lighter);
    border-radius: 20px;
    margin: 0;
    &:focus {
      outline: none;
      > span {
        box-shadow: 0 0 2px 5px red;
      }
    }
    > span:focus {
      outline: none;
    }
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: var(--white);
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background-color: var(--tertiary-color);
      color:  var(--black);
      opacity: 0.3;
    }
  }
  &-disabled {
    background-color: var(--primary-color-lighter);
    cursor: not-allowed;
    &:before {
      background-color: var(--primary-color-lighter);
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: var(--primary-color-lighter);
    color:  var(--primary-color-lighter);
    text-align: right;
  }
  &-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background:  var(--primary-color);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid var(--primary-color-lighter);
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      background:  var(--tertiary-color);
      right: 0px;
    }
  }
  &.small-switch {
    width: 55px;
    .toggle-switch-inner {
      &:after,
      &:before {
        height: 30px;
        line-height: 30px;
      }
    }
    .toggle-switch-switch {
      width: 24px;
      right: 24px;
      margin: 3px;
    }
  }
  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.825);
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.75);
  }
}

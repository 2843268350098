:root {
  /* COLORS */
  --primary-color: #1E5E9C;
  --primary-color-dark: #092B4D;
  --primary-color-light: #0195d4;
  --primary-color-lighter: #d7f2fd;

  --secondary-color: #F0A457;

  --tertiary-color: #65B766;
  --tertiary-color-dark: #468047;

  --footer-color: #F2F2F2;

  --success-color: #d0e9d6;
  --success-color-dark: #48a15d;
  --warning-color: #fbe4d5;
  --warning-color-dark: #db8145;
  --error-color: #ffd4d4;
  --error-color-dark: #d33939;
  --info-color: #dbeef6;
  --delete-color: #dc004e;

  --grey-darker: #444444;
  --grey-dark: #737373;
  --grey-dark-2: #757575;
  --grey: #a6a6a6;
  --grey-light: #d9d9d9;
  --grey-lighter: #ececec;

  --orange-text: #DD7813;
  --green-text: #4EA650;

  --valid-flag-color: #71BC16;
  --modified-flag-color: #F09942;
  --deleted-flag-color: #DD3636;

  --black: #1d1d1b;
  --white: #ffffff;

  /* RESPONSIVE WIDTHS */
  --wrapperWidthMax: 1300px;
  --wrapperWidthMin: 320px;

  --smallWrapperWidthMax: 1000px;
  --largeWrapperWidthMax: 1400px;

  --tabletWidth: 768px;

  /* MISC */
  --transitionTime: 0.3s;

  --header-height: 80px;

  --zindexSmall: 10;
  --zindexMedium: 50;
  --zindexBig: 100;
}
